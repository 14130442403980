/* eslint-disable */
import on from '../../utils/events_delegation';

/**
 * This module creates the mobile filters panel (filters + order) when needed
 */

// Creating mobile filter panels: filter and order
import './order_panel.scss';
import _debounce from 'lodash/debounce';
import inViewport from '../../utils/inViewport';
import { currentDevice } from '../../core/utils/currentDevice';

const AUTO_OFFER_LIST_PRESENT = document.querySelector('.automatic-offer-list');

const $document = $(document);
export function initFilterOrderPanel() {
  let $hotelsFiltering;
  if ($('.hotels-info-with-filtering').length > 0) {
    $hotelsFiltering = $('.hotels-info-with-filtering');
  } else if ($('.destination-index-container').length > 0) {
    $hotelsFiltering = $('.destination-index-container');
  } else if ($('.hotel-list').length > 0) {
    $hotelsFiltering = $('.hotel-list');
  } else {
    return false;
  }

  if ($hotelsFiltering.hasClass('filter-order-panel-initialized')) return false;

  let $filtersSidebar = $('.filters-sidebar');
  let $sortLinks = $('.js-sort-links');
  let $footerFilterButtons = $('.js-footer-filter-buttons');
  let $footerFilterButtonsWrapper = $('.js-footer-filter-buttons-wrapper');
  let orderLabel = '';
  let $orderOptions = {};

  if ($sortLinks.length) {
    orderLabel = $sortLinks.attr('data-order-label');

    // Get order options list
    let $currentOrderType = $sortLinks.find('a.active').attr('data-js-selector');

    $orderOptions = $('<div>')
      .attr('id', 'order-list')
      .addClass('filters formz mobile-panel__option-list js-order-option-list')
      .attr('data-current-order', $currentOrderType)
      .attr('data-trigger-click', 'data-root-destination')
      .attr('style', 'display:block;');

    let counter = 0;

    // get order options list
    $('.js-sort-links')
      .find('li')
      .each(function (index, el) {
        if (counter < document.querySelectorAll('.js-sort-links li').length / document.querySelectorAll('.js-sort-links').length) {
          let $link = $(el).find('a');
          let linkJsSelector =  ($link.data('order-type') && $link.data('order-type').length) ? $link.data('order-type') : $link.data('js-selector');

          let isChecked = $currentOrderType === linkJsSelector ? ' checked ' : '';
          let $radioButton =
            '<input type="radio" name="[filter_order]" id="' +
            linkJsSelector +
            '_panel' +
            '" class="js-pagination-mobile-order-by" value="' +
            linkJsSelector +
            '"' +
            isChecked +
            '>';
          let $radioButtonLabel = '<label class="t-p label-col mobile-panel__option-item" for="' + linkJsSelector + '_panel' + '">' + $link.text() + '</label>';
          $orderOptions.append($radioButton + $radioButtonLabel + '<br>');
          counter++;
        }
      });
  }

  $footerFilterButtonsWrapper.removeClass('is-hidden');

  if ($filtersSidebar.length || $('.hotels-info-with-filtering .filters-sidebar').length) {
    // Panel with filters and order options
    // Remove old panel if exists
    $('#mobile-filter-panel')?.remove();
    $filtersSidebar = $('.filters-sidebar')
    // Filter panel creation
    let $mobileText = $hotelsFiltering.data('mobile-filter-link-text');
    let $filtersPanelTitle = $('<p class="panel-title">' + $mobileText + '</p>');
    let $panel = $(
      '<div id="mobile-filter-panel" role="tabpanel" aria-labelledby="mobile-filter-panel mobile-filter-panel-trigger-sticky" class="mobile-panel filters-panel js-filters-panel" data-mobile-panel data-inline></div>'
    );

    // Append panel components
    if ($sortLinks.length && document.querySelectorAll('.js-order-panel').length == 0) {
      // Order filter panel creation
      let $orderFilterPanel = $('<div class="filters-block active order-panel js-order-panel"></div>');
      let $orderFilterTitle = $('<a class="title active" href="javascript:void(0)" aria-expanded="false">' + orderLabel + '</a>');

      $orderFilterPanel.append($orderFilterTitle, $orderOptions);
      $filtersSidebar.prepend($orderFilterPanel);
    }

    if (!document.querySelector('#mobile-filter-panel.ready')) {
      $panel.append($filtersPanelTitle, $filtersSidebar);
      $('body').append($panel);
    }

    // Footer buttons
    $footerFilterButtons.find('.js-footer-filter-order').remove();
  } else if ($sortLinks.length) {
    // Remove old panel if exists
    $('#mobile-filter-panel-order')?.remove();
    // Panel with order options only
    let $orderPanelTitle = $('<p class="panel-title">' + orderLabel + '</p>');
    let $closePanelButton = $('<a href="#" class="apply-btn close-mobile-panel" data-close-panel=""><span class="s s-close-fat"></span></a>');
    let $applyText = $('.order-list-link').data('mobile-panel-apply');
    let $orderPanelButton = $(
      '<a class="secondary-btn mobile-only js-apply-filters apply-filters-button" role="tab" aria-controls="mobile-filter-panel-order" data-close-panel="" href="#">' +
        $applyText +
        '</a>'
    );
    let $panelOrder = $(
      '<div id="mobile-filter-panel-order" role="tabpanel" aria-labelledby="mobile-filter-panel-order-trigger mobile-filter-panel-order-trigger-sticky" class="mobile-panel filters-panel order-panel js-order-panel right ready" data-mobile-panel panel-option data-inline></div>'
    );

    $panelOrder.append($orderPanelTitle, $orderOptions, $orderPanelButton, $closePanelButton);
    $('body').append($panelOrder);

    // Footer buttons
    $footerFilterButtons.find('.js-footer-filter-filter').remove();
  }

  // Apply default order
  if ($sortLinks.length) {
    applyOrder();
  }

  //Initialize elements of panel
  $('.filters-panel').find('input[type=checkbox], input[type=radio], select').formikation();

  // Footer filter buttons visibility
  let $buttonLabels = $footerFilterButtons.find('.js-footer-filter-label');
  let widthApplied = false;
  let lastScrollTop = 0;
  let delta = 5;
  let windowHeight = $(window).outerHeight();
  let finishLoad = false;
  let filtersBlockVisible = false;
  const $filterButtons = document.querySelector('.js-special-offer-mobile-filter-group');

  if ($filterButtons) {
    // Show floating filters if page filters block is not visible
    inViewport(
      $filterButtons,
      element => {
        filtersBlockVisible = element.isIntersecting;
      },
      { threshold: 1 }
    );
  }

  $(window).scroll(
    _debounce(() => {
      let windowScrollTop = $(window).scrollTop();
      let hotelsFilteringOffset = $hotelsFiltering.offset();
      let wrapperOffsetBottom = hotelsFilteringOffset.top + $hotelsFiltering.outerHeight() + 40 - windowScrollTop < 0;
      let topReachedFilters = hotelsFilteringOffset.top - 40 - (windowScrollTop + windowHeight) < 0;
      let mustBeVisible = topReachedFilters && !wrapperOffsetBottom && !filtersBlockVisible;

      $footerFilterButtonsWrapper.toggleClass('is-active', mustBeVisible);

      // Make sure they scroll more than delta
      if (Math.abs(lastScrollTop - windowScrollTop) <= delta) return;

      if (windowScrollTop > lastScrollTop) {
        // Scroll Down
        if (!$footerFilterButtons.hasClass('is-collapsed')) {
          let visibilityOffsetBottom = hotelsFilteringOffset.top + 140 - windowScrollTop < 0;
          $footerFilterButtons.toggleClass('is-collapsed', visibilityOffsetBottom);
        }
      } else {
        // Scroll Up
        $footerFilterButtons.removeClass('is-collapsed');
      }
      lastScrollTop = windowScrollTop;

      // set default label width
      if (!widthApplied && topReachedFilters && !$footerFilterButtons.hasClass('is-collapsed') && $buttonLabels.length && $buttonLabels.eq(0).outerWidth() !== 0) {
        $buttonLabels.each(function (index, el) {
          let $this = $(this);
          $this.removeAttr('style');
          $this.css('width', $this.outerWidth() + 'px');
        });
        widthApplied = true;
      }
    }, 50)
  );

  $hotelsFiltering.addClass('filter-order-panel-initialized')
}

// MAP panel
$(document).on('click', '.js-footer-filter-buttons .hotel-map-link', function (event) {
  event.preventDefault();
  if ($(this).hasClass('disabled')) return false;

  if (!$(this).hasClass('map-is-activated')) {
    IB.hotels_filter_map.init($(this), {
      type: 'visible',
    });
  } else {
    IB.hotels_filter_map.filterVisible();
  }
});

// Checks if order filter has been changed by user
/**
 *
 * @return {boolean} - true if changed, false if not changed
 */
function orderOptionChanged() {
  let $orderOptionList = $('.js-order-option-list');

  if (!$orderOptionList.length) return;

  let lastOrderFilter = $orderOptionList.attr('data-current-order');
  let selectedOrderFilter = $orderOptionList.find('input:checked').val();
  return lastOrderFilter !== selectedOrderFilter;
}

/**
 * Sorts list by order option selected
 */
function applyOrder() {
  let $orderOptionList = $('.js-order-option-list');

  if (!$orderOptionList.length) return;

  let selected_option = $orderOptionList.find('input:checked').val();

  if (!selected_option) return;

  $orderOptionList.attr('data-current-order', selected_option);
  $orderOptionList.find('label[for="' + selected_option + '"]').click();
  $('[data-js-selector="' + selected_option + '"]').click();
}

// Events
// Click on filters apply button
$document.on('click', '[data-mobile-panel] .js-apply-filters', function () {
  // Apply current selected order filter
  if ($('.js-sort-links').length && orderOptionChanged()) {
    applyOrder();
  }
});

function resetPanel() {
  document.querySelector('.mobile-panel.filters-panel').addEventListener(
    'transitionend',
    function (event) {
      document.querySelectorAll('.filters-block').forEach(function (item) {
        item.style.display = 'block';
      });
    },
    { once: true }
  );
}


document.addEventListener("DOMContentLoaded", function() {
  // Code to be executed when the DOM is fully loaded
  if (AUTO_OFFER_LIST_PRESENT) {
    if (currentDevice.isMobile || currentDevice.isLikeMobile) {
      // Order panel
      //---------------------------------------------------------//

      on({
        eventName: 'click',
        selector: '.js-filter-order-only',
        handler() {
          document.querySelectorAll('.filters-block:not(.js-order-panel)').forEach(function (item) {
            item.style.display = 'none';
          });
        },
      })

      // No order panel
      //---------------------------------------------------------//

      on({
        eventName: 'click',
        selector: '.js-filter-all',
        handler() {
          document.querySelector('.filters-block.js-order-panel').style.display = 'none';
        },
      })

      // Reset panel class
      //---------------------------------------------------------//

      on({
        eventName: 'click',
        selector: '.js-filters-panel .close-mobile-panel, .bottom-actions',
        handler() {
          resetPanel();
        },
      })

      //---------------------------------------------------------//
    }
  }

  if ((currentDevice.isMobile || currentDevice.isLikeMobile) && $('.js-order-panel').length) {
    on({
      eventName: 'click',
      selector: '.js-order-panel',
      handler() {
        // Toggle filter
        document.querySelector('.js-order-panel a').classList.toggle('active');
        document.querySelector('.mobile-panel__option-list').classList.toggle('is-hidden');
      },
    })
  }
});
