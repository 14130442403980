import '../../shared/filters_sidebar/filters_sidebar'

import { currentDevice } from '../../core/utils/currentDevice'
import { initFilterOrderPanel } from '../../shared/filter_order_panel/filter_order_panel'

import { destroyUtagEvents } from './destinations_index.analytics'

let handleRootDestinationClick
let handleFilteringFinish
let handleDestinationIndexSelectChange
let handleDocumentScroll
let handleClosePanelClick
let handleFilterDestListLinkClick

/* eslint-disable */
export function init() {

  //initializeUtagEvents();

  if (currentDevice.isMobile && !document.body.classList.contains('inspiration-guide')) {
    initFilterOrderPanel();
    $('[data-mobile-panel]').mobilepanel();
  }

  if (!$('[data-root-destination]').length) return false;

  let $root_destinations = $('.root-destination');

  handleRootDestinationClick = function() {
    selectDestination($(this))
  }

  // Root tabs del indice de destinos
  $('[data-root-destination]').on('click', handleRootDestinationClick);

  function selectDestination (elem) {
    let $el = elem;
    let $target = $('.' + $el.attr('data-root-destination'));

    $('[data-root-destination]').removeClass('active');
    $el.addClass('active');

    if (!currentDevice.isMobile) {
      if ($('#hotels-filter-map').is(':visible')) {
        $root_destinations.not($target).removeClass('current');
        $target.addClass('current');
        IB.hotels_filter_map.filterVisible();
        $('.js-filters-sidebar').find('.root-destination').not($target)
          .addClass('hidden');
        $('.js-filters-sidebar').find($target).removeClass('hidden');
      } else {
        $root_destinations.not($target).addClass('hidden');
        $target.removeClass('hidden');
      }
    } else {
      $root_destinations.not($target).addClass('hidden');
      $target.removeClass('hidden');
    }
    if($('[data-filterable-wrap]').length)
      window.FilterSidebar.clear($('[data-filterable-wrap]'));

    elem.get(0).dispatchEvent(new CustomEvent('root-destination:tab-changed'))
  }

  handleFilteringFinish = function (e, filters) {
    // Creamos un contador en cada filtro destino que exista en el sidebar
    let $dest_filters = $('[data-filter="destinations"]');
    for (let i = 0; i < $dest_filters.length; i++) {
      $($dest_filters[i]).data('hotel-count', 0);
    }

    // Iteramos sobre cada listado de destinos root
    $root_destinations.filter('.destination-list').each(function () {
      let $root_destination = $(this);
      let root_id = $root_destination.attr('data-destination');
      let $destinations = $root_destination.find('.js-dest-card');
      let root_counter = 0;

      // Actualizamos los contadores de hoteles de cada destino
      $destinations.each(function () {
        let $dest = $(this);
        let $current_dest_filter = $dest_filters.filter('[value="' + $dest.attr('data-destination') + '"]');
        let hotels = $dest.data('filterable');
        let hotels_counter = hotels.length;
        let dest_counter = $current_dest_filter.data('hotel-count');

        $.each(hotels, function (i, hotel) {
          let hotel_match = [true];
          $.each(filters, function (type, filters_values) {
            if (filters_values.length == 0 || type == 'destinations') return;
            hotel_match.push(FilterSidebar.contains(hotel[type], filters_values));
          });
          if (!hotel_match.reduce(function (a, b) {return a && b;})) hotels_counter--;
        });

        // Contador de cada destino del sidebar
        $current_dest_filter.data('hotel-count', dest_counter += hotels_counter);
        // Contador de la propia card del destino
        $dest.find('.hotel-count b').text(hotels_counter);
        if (hotels_counter > 0) root_counter += hotels_counter;
      });

      // Contador del destino root
      $('[data-root-destination="destination-' + root_id + '"]').find('.hotel-count b').text(root_counter);
    });

    $dest_filters.each(function () {
      let $el = $(this);
      $el.siblings('.hotel-count').find('b').text($el.data('hotel-count'));
    });
  }

  // Cada vez que termina el filtrado del sidebar actualizamos contadores
  $('.destination-index-container').on('filtering.finish', handleFilteringFinish);

  // Triggeamos el click al cambio del destination select
  $(document).on('change', '.js-destination-select', function (event) {
    event.preventDefault();
    let $selected = $(this).find(':selected');
    selectDestination($selected);

    dataLayer.push($selected.data('gtm-click'));
  });

  // GTM event open destination select
  $(document).on('click', '.js-destination-select', function (event) {
    let $selected = $(this).find(':selected');
    let data = $selected.data('gtm-click');

    data['eventAction'] = 'Abrir desplegable continente';
    dataLayer.push(data);
  });

  if (!currentDevice.isMobile) return false;

  // Creamos el select de destinos

  // TEST M307115

  $('[data-root-destination]').each(function (index, el) {
    let $el = $(el).clone();
    let data_root = $el.attr('data-root-destination');
    $el.find('.hotel-count').remove();

    $('.destination-index-select').append('<option value="' + data_root + '">' + $el.text() + '</option>');
  });

  handleDestinationIndexSelectChange = function (event) {
    event.preventDefault();
    let root_destination = $(this).val();
    $('[data-root-destination="' + root_destination + '"]').trigger('click');
  }

  // Triggeamos el click al cambio del select
  $(document).on('change', '.destination-index-select', handleDestinationIndexSelectChange);


  // Creamos el "mobile panel" con filtros
  let label = $('.filter-dest-list-link').data('mobile-panel-title');
  let $filters_sidebar = $('.js-filters-sidebar');
  let $filters_panel_title = $('<p class="panel-title">' + label + '</p>');
  let $panel = $('<div id="mobile-filter-panel" class="mobile-panel filters-panel" data-mobile-panel data-inline></div>');
  let $destination_filter = ($('.destination-index-tabs-nav').length) ? '.destination-index-tabs-nav' : '.destination-select select';
  let $destination_filter_selection = ($('.destination-index-tabs-nav').length) ? 'li.active' : 'option:selected';
  let $default_value = $($destination_filter).find($destination_filter_selection).attr('data-root-destination');
  let $list = $('<div>').attr('id', 'filter-dest').addClass('formz filter-dest-panel')
    .attr('data-current-selection', $default_value)
    .attr('data-panel-option-list', '')
    .attr('data-trigger-click', '');

  $($destination_filter).find('[data-root-destination]').each(function (index, el) {
    let $radio_button = '<input type="radio" name="[filter_order]" id="' + $(el).data('root-destination') + '" value="' + $(el).data('root-destination') + '">';
    let final_text = $(el).html();
    let $radio_button_label = '<label class="t-p label-col" for="' + $(el).data('root-destination') + '">' + final_text + '</label>';
    if ($('#filter-dest').length <= 0) $list.append($radio_button + $radio_button_label + '<br>');
  });

  let $filter_dest_panel_title = $('<p class="panel-title">' + label + '</p>');
  let $apply_text = $('.filter-dest-list-link').data('mobile-panel-apply');
  let $filter_dest_panel_button = $('<a class="secondary-btn mobile-only" data-close-panel="" href="#">' + $apply_text + '</a>');
  let $panel_filter_dest = $('<div id="mobile-filter-panel-dest" class="mobile-panel filters-panel mobile-filter-panel-dest" data-mobile-panel data-inline></div>');

  $('body').append($panel, $panel_filter_dest);
  $('#mobile-filter-panel').append($filters_panel_title, $filters_sidebar);
  $('#mobile-filter-panel-dest').append($filter_dest_panel_title, $list, $filter_dest_panel_button);

  handleDocumentScroll = function () {
    hideOrShowFilterNav();
  }

  $(document).scroll(handleDocumentScroll);

  handleClosePanelClick = function () {
    setTimeout(function () {
      hideOrShowFilterNav();
    }, 200);
  }

  $(document).on('click', '[data-close-panel]', handleClosePanelClick);

  handleFilterDestListLinkClick = function () {
    let $current_selected = $('[data-panel-option-list]').attr('data-current-selection');
    $('#filter-dest input[value="' + $current_selected + '"]').click();
  }
  $(document).on('click', '.filter-dest-list-link', handleFilterDestListLinkClick);

  function hideOrShowFilterNav() {
    let header_is_visible = $('.mobile-header').hasClass('nav-down');
    let page_top = $(document).scrollTop() <= 40;
    $('.filters').toggleClass('nav-down', header_is_visible || page_top);
  }

}

export function destroy() {
  destroyUtagEvents()
  if (currentDevice.isMobile) {
    $('[data-mobile-panel]').mobilepanel('destroy');
  }

  if (!$('[data-root-destination]').length) return

  $('[data-root-destination]').off('click', handleRootDestinationClick);
  $('.destination-index-container').off('filtering.finish', handleFilteringFinish);
  $(document).off('change', '.js-destination-select', handleDestinationIndexSelectChange);
  $(document).off('scroll', handleDocumentScroll);
  $(document).off('click', '[data-close-panel]', handleClosePanelClick);
  $(document).off('click', '.filter-dest-list-link', handleFilterDestListLinkClick);
  $('#mobile-filter-panel').remove();
  $('#mobile-filter-panel-dest').remove();
}

init();
